<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-icon>fas fa-filter</v-icon>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <!--#region Theme  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t(val.EFAnnotation.label) }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.EFAnnotation.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.EFAnnotation.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region Declarant_IIN_BIN  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.Declarant_IIN_BIN.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    v-model="val.Declarant_IIN_BIN.value"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    @keypress="onlyNumber"
                                    clearable
                                    @click:clear="val.Declarant_IIN_BIN.value = null"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region StQuestion  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.StQuestion.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="questionItems"
                                    v-model="val.StQuestion.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.StQuestion.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchStQuestion"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region PerCategory  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.PerCategory.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="personCategoryItems"
                                    v-model="val.PerCategory.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.PerCategory.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region StLanguage  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.StLanguage.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="statementLanguageItems"
                                    v-model="val.StLanguage.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.StLanguage.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region StForm  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.StForm.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="statementFormItems"
                                    v-model="val.StForm.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.StForm.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region StType  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.StType.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="statementTypeItems"
                                    v-model="val.StType.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.StType.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region ExecuterWorkplaceId  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.ExecuterWorkplaceId.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="statementExecuterItems"
                                    v-model="val.ExecuterWorkplaceId.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.ExecuterWorkplaceId.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchExecuterWorkplaceId"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region ControlDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.ControlDateFrom.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="executionDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.ControlDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.ControlDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.ControlDateFrom.value"
                                        @input="executionDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region ControlDateTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.ControlDateTo.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="executionDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.ControlDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.ControlDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.ControlDateTo.value"
                                        @input="executionDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region IsRedirected  -->
                        <v-row v-if="val.EUOL_IsRedirected" no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.EUOL_IsRedirected.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="IsRedirectValues"
                                    v-model="val.EUOL_IsRedirected.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.EUOL_IsRedirected.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save" 
                        v-if="isFormValid"
                    >
                        {{$t("Применить_фильтр")}}                    
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';

export default {
    name: "ChancellaryStatementExFilter",
    props:
    {
        value:
        {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            title: "Фильтр",
            isFormValid: true,
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            val: JSON.parse(JSON.stringify(this.value)),
            
            questionItems: [],
            personCategoryItems: [],
            statementLanguageItems: [],
            statementFormItems: [],
            statementTypeItems: [],
            statementExecuterItems: [],
            
            executionDateFromMenu: false,
            executionDateToMenu: false,

            IsRedirectValues: [
                { id: true, Value: this.$t("Да") },
                { id: false, Value: this.$t("Нет") }
            ],
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    methods: {
        ...mapActions('references', ['getReference', 'getWorkplaces']),
        ...mapActions(['setOverlayVisible']),
        ...mapGetters('references', ['GetDeclarantCategoryTypes']),
        async initialize() {
            this.questionItems = await this.getReference({ id: 1003 });
            
            this.personCategoryItems = this.GetDeclarantCategoryTypes();
            this.statementLanguageItems = await this.getReference({ id: 5206 });
            this.statementFormItems = await this.getReference({ id: 5204 });
            this.statementTypeItems = await this.getReference({ id: 5205 });
            this.statementExecuterItems = (await this.getWorkplaces()).InnerExecuters.Executers.map(i =>                 
                ({
                    id: i[0],
                    Value: i[1],
                    employeeId: i[2],
                    jobTitle: i[3]
                })
            );
        },
        async open(options = {})
        {
            this.val = JSON.parse(JSON.stringify(this.value)),
            this.visible = true;
            this.options = Object.assign(this.options, options);

            this.setOverlayVisible({ visible: true });
            this.initialize();
            this.setOverlayVisible({ visible: false });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.$emit('input', this.val);
            this.resolve();
        },
        handleDate(source) {
            var formatedDate = sys.dateFormat(source, 'DD.MM.YYYY');
            return formatedDate ?? "";
        },
        async searchStQuestion(e) {
            if (e) {
                this.questionItems = (await this.getReference({ id: 1003 })).filter( i => sys.checkSearch(i.Value, e));
            }
            else
                this.questionItems = await this.getReference({ id: 1003 });
        },
        async searchExecuterWorkplaceId(e) {
            if (e) {
                this.statementExecuterItems = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[2], Value: i[1], jobTitle: i[3]})).filter( i => sys.checkSearch(i.Value, e));
                }
            else
                this.statementExecuterItems = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[2], Value: i[1], jobTitle: i[3]}));
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            if (keyCode < 48 || keyCode > 57)
                $event.preventDefault();
        }
    }
}
</script>